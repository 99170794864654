<template lang="pug">
div.adock-section.adock-section-grey
  v-container.pt-0
    v-row
      v-col
        h2 Statistiques
        span Depuis la mise en ligne du service en juillet 2018.
    v-row
      v-col
        h3 Assurer la visibilité des entreprises
        span Grâce à une fiche entreprise présentant leurs données professionnelles essentielles.
    v-row
      v-col.pt-0(cols="12" md="4")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title 100 %
          v-card-subtitle.text-center.adock-stats-subtitle des entreprises en France référencées
          v-card-text
            | Qu’elles aient créé un compte ou non sur A Dock, l’ensemble des entreprises de
            | transport routier en France disposent déjà d’une fiche présentant les données
            | issues du Registre des transports ainsi que de la base Sirene, accessible au
            | travers du moteur de recherche.
      v-col.pt-0(cols="12" md="4")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title 30
          v-card-subtitle.text-center.adock-stats-subtitle données déjà disponibles par fiche entreprise
          v-card-text
            | Données de contact et d’activité, administratives, Objectif CO2 de l’ADEME…
            | Nos fiches entreprises présentent déjà une trentaine de données, qui ont vocation à s’enrichir
            | avec des nouvelles données pertinentes aux échanges transporteurs - expéditeurs : Kbis,
            | assurances…
      v-col.pt-0(cols="12" md="4")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title {{ carrierViews.toLocaleString() }}
          v-card-subtitle.text-center.adock-stats-subtitle consultations de fiches entreprises
          v-card-text
            | À ce jour,
            |
            span.adock-stats-counter {{ carrierSearches.toLocaleString() }}
            |
            | recherches d’entreprises ont été effectuées
            | sur notre moteur de recherche, avec un nombre croissant de fiches consultées.
            | La liste des résultats donne la priorité aux entreprises avec des données à jour,
            | locales et spécialisées sur l'activité.
    v-row
      v-col
        h3 Assurer un accès aux données
        span Grâce à un moteur de recherche ouvert et gratuit pour tous les commanditaires du transport routier.
    v-row
      v-col.pt-0(cols="12" md="4")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title {{ stats.carrier && stats.carrier.count.toLocaleString() }}
          v-card-subtitle.text-center.adock-stats-subtitle fiches entreprises en consultation libre
          v-card-text
            | L’ensemble des entreprises et établissements inscrits au registre national disposent
            | d’une fiche entreprise individuelle sur la plateforme A Dock.
      v-col.pt-0(cols="12" md="4")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title {{ completedCarriers.toLocaleString() }}
          v-card-subtitle.text-center.adock-stats-subtitle fiches entreprises complétées
          v-card-text
            | À leur inscription, les transporteurs viennent compléter leur fiche entreprise avec
            | leurs données de contact, leurs aires de travail et d’activité, spécialités et attestations,
            | utiles à la bonne conduite et simplification de leurs relations commerciales.
      v-col.pt-0(cols="12" md="4")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title {{ certificateViews.toLocaleString() }}
          v-card-subtitle.text-center.adock-stats-subtitle attestations téléchargées
          v-card-text
            | Les transporteurs peuvent générer et mettre à disposition des expéditeurs leur
            | attestation d’emploi ou de non emploi de travailleurs soumis à autorisation de
            | travail sur leur fiche entreprise.
            | Vérifiables via un QR code, elles sont directement téléchargeables depuis leurs fiches entreprises.
    v-row
      v-col
        h3 Simplifier la gestion administrative des entreprises
        span Grâce à la dématérialisation de démarches essentielles aux entreprises de transport.
    v-row
      v-col.pt-0(cols="12" md="4")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title 4
          v-card-subtitle.text-center.adock-stats-subtitle démarches disponibles
          v-card-text
            | en France entière
            ul
              li attestation d’emploi ou de non emploi de travailleurs soumis à autorisation de travail.
            | en région Bretagne
            ul
              li renouvellement des licences de transport
              li demande de copies de licences supplémentaires
              li demande d’accès à la profession de transporteur routier de marchandises.
      v-col.pt-0(cols="12" md="4")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title {{ procedures }}
          v-card-subtitle.text-center.adock-stats-subtitle démarches réalisées
          v-card-text
            span.adock-stats-counter {{ certificates }}
            |
            | attestations générées
            br
            span.adock-stats-counter {{ licenseRenewals }}
            |
            | renouvellements des licences de transport
            br
            span.adock-stats-counter {{ licenseCopies }}
            |
            | demandes de copies de licences supplémentaires
            br
            span.adock-stats-counter {{ registrations }}
            |
            | demandes d'accès à la profession de transporteur routier de marchandises
      v-col.pt-0(cols="12" md="4")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title {{ wonHours }}
          v-card-subtitle.text-center.adock-stats-subtitle heures gagnées
          v-card-text
            | Temps moyen gagné par démarche : 55 minutes.
            | Sur adock.beta.gouv.fr, l’utilisateur met en moyenne 5 min à créer son compte
            | et réaliser la procédure dématérialisée désirée (au lieu des environ 60 minutes
            | lorsqu’il s’agit de réaliser sa démarche par Cerfa papier, constituer le dossier
            | avec les pièces justificatives nécessaires, dépôt ou envoi en DREAL).
    v-row
      v-col
        h3 Nos données techniques
        span Versions de l'application et actualisation des données.
    v-row
      v-col.pt-0(cols="12" md="3")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title {{ backendVersion }}
          v-card-subtitle.text-center.adock-stats-subtitle version de l'application serveur
      v-col.pt-0(cols="12" md="3")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title {{ frontendVersion }}
          v-card-subtitle.text-center.adock-stats-subtitle version de l'application UI
      v-col.pt-0(cols="12" md="3")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title {{ registreDate | asLocaleDate }}
          v-card-subtitle.text-center.adock-stats-subtitle mise à jour du registre des transports de marchandises
      v-col.pt-0(cols="12" md="3")
        v-card.adock-card.fill-height
          v-card-title.justify-center.adock-stats-title {{ sireneDate | asLocaleDate }}
          v-card-subtitle.text-center.adock-stats-subtitle mise à jour de la base SIRENE
</template>

<script>
import { mapState } from "vuex";

import { version } from "@/../package.json";
import api from "@/api";

export default {
  created() {
    this.setup();
  },

  data() {
    return {
      completedCarriers: 0,
      certificates: 0,
      licenseRenewals: 0,
      licenseCopies: 0,
      registrations: 0,

      backendVersion: "",
      frontendVersion: "",

      sireneDate: null,
      registreDate: null,

      carrierViews: 0,
      certificateViews: 0,
      carrierSearches: 0,
    };
  },

  computed: {
    procedures() {
      return (
        this.certificates +
        this.licenseRenewals +
        this.licenseCopies +
        this.registrations
      );
    },

    wonHours() {
      return (~~(this.procedures * 0.92)).toLocaleString();
    },

    ...mapState(["stats"]),
  },

  methods: {
    asLocaleMonth(value) {
      return new Date(value).toLocaleString("fr", { month: "long" });
    },

    async setup() {
      const response = await api.get(api.statsUrl);
      if (response.status === 200) {
        const data = response.data;
        this.completedCarriers = data.completed_carriers;
        this.certificates = data.certificates;
        this.licenseRenewals = data.license_renewals;
        this.licenseCopies = data.license_copies;
        this.registrations = data.registrations;

        this.backendVersion = data.backend_version;
        this.frontendVersion = version;

        this.sireneDate = data.sirene_date;
        this.registreDate = data.registre_date;

        // From nginx
        if (data.carrier_views) {
          this.carrierViews = data.carrier_views;
        }
        if (data.certificate_views) {
          this.certificateViews = data.certificate_views;
        }
        if (data.carrier_searches) {
          this.carrierSearches = data.carrier_searches;
        }
      }
    },
  },
};
</script>
